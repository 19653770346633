<template>
  <router-view></router-view>
</template>

<script>
import { onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {},
  setup() {
    const store = useStore()
    store.dispatch('account/requestBase')
    store.dispatch('settings/reqBase', { model: 'bg', endpoint: 'companyBackgrounds'})
    store.dispatch('settings/reqBase', { model: 'logos', endpoint: 'companyLogos'})
    store.dispatch('settings/reqBase', { model: 'banners', endpoint: 'companyBanners'})

    const tabs = [
      { label: 'tabs.settings_basic', to: { name: 'SettingsBasic' } },
      { label: 'tabs.settings_bg', to: { name: 'SettingsBG' } },
      { label: 'tabs.settings_logo', to: { name: 'SettingsLogo' } },
      { label: 'tabs.settings_banner', to: { name: 'SettingsBanner' } },
    ]
    store.commit('header/setTabs', tabs)
    onBeforeUnmount(() => {
      store.commit('header/setTabs', '')
    })
  }
}
</script>
